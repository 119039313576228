<template>
  <main>
    
    <section>
      <div>
        <img src="@/assets/blue-house.jpg" />
      </div>

      <div class="message" v-if="home">
        <label v-text="home.title"></label>
        <div class="message-content message-content-1" v-text="home.subtitle"></div>
        <div class="message-content message-content-2" v-text="home.text"></div>
      </div>
    </section>

    <section>
      <label>Trips</label>
      <div class="flex-container">
        <template v-for="trip in trips">
          <router-link
            :key="trip.id"
            :to="`/trips/${trip.id}`"
            class="trip flex-item"
            :style="`background-image: url('${trip.trip_img}')`"
          >
            <div class="trip-details">
              <div class="trip-header" v-text="trip.displayName"></div>
              <div class="trip-subheader" v-text="trip.displayName2"></div>
              <div class="trip-subheader" v-text="trip.date"></div>
              <div class="trip-content" v-text="trip.shortDescription"></div>
            </div>
          </router-link>
        </template>
      </div>
    </section>
    
    <section>
      <div>
        <label>Contact Us</label>
        <div class="board-members">
          <template v-for="member in topBoardMembers">
            <a
              :key="member.id"
              :href="'mailto:'+ member.email + '?body=Hello ' + member.name + ', \n\n'"
              target="_blank"
            >
              <span>{{member.name}} </span>
              <span>({{member.role}})</span>
              <span>{{member.email}}</span>
            </a>
          </template>

        </div>
        <router-link to="/contact" custom class="board-of-directors">
          Board of Directors
        </router-link>
      </div>
      <div>
        <label>Join the Community</label>
        <div class="join-the-community">
          <a class="email-btn" href="mailto:bwbutah@gmail.com" target="_blank">
            bwbutah@gmail.com
          </a>
        </div>
      </div>
      <div>
        <label>Looking to Donate?</label>
        <div class="looking-to-donate">
          <router-link
            class="donate-btn"
            to="/donate"
            custom
          >Donate</router-link>
        </div>
      </div>
    </section>


  </main>
</template>

<script>
import { firebase } from "../plugins";


export default {
  name: "Home",
  data: () => ({
    home: {},
    trips: [],
    topBoardMembers: []
  }),
  firestore: {
    home: firebase.pagesRef.doc("home"),
    trips: firebase.tripsRef.orderBy("order"),
    topBoardMembers: firebase.boardRef.orderBy("order").limit(3)
  }
};
</script>

<style scoped>
/** Main and Generic */
main {
  display: flex;
  flex-direction: column;

  /* max-width: 900px; */
  margin-left: auto;
  margin-right: auto;
}

main > section {
  display: flex;
  flex-direction: column;

  padding: 48px var(--main-right-margin) 96px var(--main-left-margin);
}

main > section label {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}


/** First Section */
main > section:nth-child(1) {
  flex-direction: row;
  justify-content: space-between;
  column-gap: 72px;
}

main > section:nth-child(1) > div:nth-child(1) {
  width: 40% !important;
  position: relative;
  overflow: hidden;
  min-height: 600px;

  border-radius: 8px !important;
  perspective: 1px;
}

main > section:nth-child(1) > div > img {
  position: absolute;
  z-index: -9999;
  top: 50%;  
  left: 25%; 
  transform: translate(-25%, -50%);
  width: calc(max(min(100%, 1200px), 800px)) !important;
}

main > section:nth-child(1) > div:nth-child(2) {
  width: 60% !important;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.message > label {
  font-family: granville;
  font-weight: none;
}
.message-content {
  font-size: 18px;
  margin-top: 12px;
}
.message-content-1 {
  font-size: 42px;
  font-weight: bold;
  white-space: pre-wrap;
  font-family: granville;
}

/** Second Section : Trips */
main > section:nth-child(2) label {
  font-size: 32px;
}
main > section:nth-child(2) {
  background-color: white;
  padding-top: 76px;
  padding-bottom: 108px;
}


/** Mobile View */
@media (max-width: 800px) {

  main > section:nth-child(1) {
    flex-direction: column;
    row-gap: 72px;
  }

  main > section:nth-child(1) > div:nth-child(1) {
    margin-left: auto;
    margin-right: auto;

    width: 100% !important;
    min-height: 400px;
  }

  main > section:nth-child(1) > div > img {
    top: 0%;
    transform: translate(-25%, 0%);
  }

  main > section:nth-child(1) > div:nth-child(2) {
    width: 100% !important;
  }
}
</style>


<style scoped id="trips">
.flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 16px;
}

.trip {
  cursor: pointer;

  border-radius: 10px;
  background-size: 100% 100%;
  background-size: cover;
  transition: 0.5s;

  flex: 1 200px;
  height: 300px;
}

.trip-details {
  margin-top: 45px;
  padding: 15px 30px;
  background-color: var(--footer-color);
}

.trip-header {
  color: white;
  font-size: 24px;
  word-break: break-all;
  font-weight: bold;
}
.trip-subheader {
  color: white;
}

@media (max-width: 800px) {
  .trip {
    height: 240px;
  }
}
</style>

<style id="contact-us-style" scoped>
main > section:nth-child(3) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 24px;

  margin-top: 24px;
}

@media (max-width: 800px) {
  main > section:nth-child(3) {
    flex-direction: column;
  }
}


main > section:nth-child(3) > div {
  min-width: 320px;
}

main > section:nth-child(3) label + div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;

  margin-top: 24px;
}

main > section:nth-child(3) a {
  background-color: var(--footer-color);
  /* background-color: black; */
  color: #fff;
  cursor: pointer;

  padding: 16px 16px;
}
main > section:nth-child(3) a > * {
  color: #fff;
}

.board-members {
  margin-bottom: 48px;
}
.board-members > a span:nth-child(3) {
  display: none;
}
.board-members
  > a:hover
  > span:not(:nth-child(3))
{
  display: none;
}
.board-members
  > a:hover
  > span:nth-child(3)
{
  display: block;
  text-decoration: underline;
}

.board-of-directors {
  background-color: transparent !important;
  text-decoration: underline;
  color: var(--footer-color) !important;
  padding-left: 0px !important;
}
.board-of-directors:hover {
  color: var(--bwb-color) !important;
}
.board-of-directors:after {
  display: inline-block;
  transform: scale(0.5, 1);
  content: ">";
  margin-left: 2px;
  text-decoration: none;
}

.email-btn {
  text-decoration: underline;
}

.donate-btn {
  background-color: var(--footer-color) !important;
}
.donate-btn:after {
  display: inline-block;
  transform: scale(0.5, 1);
  content: ">";
  margin-left: 2px;
  text-decoration: none;
}
</style>
